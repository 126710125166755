import { mapMutations } from "vuex";
import moment from "moment";
import Repository from "../services/api/Repository";
import localStorage from "@/common/localStorage";

const mixins = {
    data: function () {
        return {
            codeStatusSupportFirst: 10,
            codeStatusSupportA: 20,
            codeStatusSupportB: 30,
            codeStatusSupportContinuing: 60, // 継続支援
            codeStatusSupportFinal: 40,

            INITIAL_SUPPORT: 10,
            CONTINUOUS_SUPPORT: 20,
            FINAL_EVALUATION: 30,

            BEFORE_START_SUPPORT: 10,
            SUPPORT_IN_PROGRESS: 20,
            SUPPORT_COMPLETE: 30,
            END_HALFWAY: 40,
            FIRST_INTERVIEW_DECLINE: 50,

            // Support flow segment status
            SUPPORT_FLOW_STATUS_PLAN: 10,
            SUPPORT_FLOW_STATUS_SCHEDULE: 20,
            SUPPORT_FLOW_STATUS_PROGRESS: 30,
            SUPPORT_FLOW_STATUS_CONFIRM: 40,
            SUPPORT_FLOW_STATUS_CANCEL: 50,

            HEALTH_GUIDANCE_DIV_ACTIVE: 1,

            // Support flow status
            SUPPORT_FLOW_STATUS_BEFORE_SUPPORT: 10,
            SUPPORT_FLOW_STATUS_IN_PROGRESS_SUPPORT: 20,
            SUPPORT_FLOW_STATUS_FINISH: 30,
            SUPPORT_FLOW_STATUS_PART_FINNISH_SUPPORT: 40,
            SUPPORT_FLOW_STATUS_DENIED_SUPPORT: 50,

            // // 第n期
            HEALTH_GUIDANCE_PERIOD_1: 1,
            HEALTH_GUIDANCE_PERIOD_2: 2,
            HEALTH_GUIDANCE_PERIOD_3: 3,
            HEALTH_GUIDANCE_PERIOD_4: 4,

            SYSTEM_USER: "1",
            SUPPORTER: "2",
            BACKOFFICE: "4",
            SYSTEM_MAINTAIN: "8",
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,

            // アウトカム評価ポイント
            OUTCOME_EVAL_POINT_EATING: 20,
            OUTCOME_EVAL_POINT_EXERCISE: 20,
            OUTCOME_EVAL_POINT_SMOKING: 30,
            OUTCOME_EVAL_POINT_REST: 20,
            OUTCOME_EVAL_POINT_ELSE: 20,
            OUTCOME_EVAL_POINT_1CM_1KG: 20,
            OUTCOME_EVAL_POINT_2CM_2KG: 160, // 180ポイントだが、1cm_1kgの20と合わせて180ポイントになるように設定
        }
    },

    methods: {
        currentUserID() {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                return user.id;
            }
        },
        canStop(reception, flow) {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                const roleSystem = user?.user_sys_auth_div.split(",").indexOf(this.SYSTEM_USER) >= 0 || user?.user_sys_auth_div.split(",").indexOf(this.BACKOFFICE) >= 0
                const healthGuidance = reception.health_guidance_div == this.HEALTH_GUIDANCE_DIV_ACTIVE
                const supportFlow = flow.support_flow_status == this.SUPPORT_FLOW_STATUS_IN_PROGRESS_SUPPORT || flow.support_flow_status == this.SUPPORT_FLOW_STATUS_PART_FINNISH_SUPPORT
                return roleSystem && healthGuidance && supportFlow
            }
            return false;
        },
        canReject(reception, flow) {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                const roleSystem = user?.user_sys_auth_div.split(",").indexOf(this.SYSTEM_USER) >= 0 || user?.user_sys_auth_div.split(",").indexOf(this.BACKOFFICE) >= 0
                const healthGuidance = reception.health_guidance_div
                const supportFlow = flow.support_flow_status == this.SUPPORT_FLOW_STATUS_BEFORE_SUPPORT || flow.support_flow_status == this.SUPPORT_FLOW_STATUS_DENIED_SUPPORT
                return roleSystem && healthGuidance && supportFlow
            }
            return false;
        },

        //##################################################################################################################
        // システム権限
        isSystemAdminOrBackOffice() {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                return (user?.user_sys_auth_div.split(",").indexOf(this.BACKOFFICE) >= 0) ||
                    (user?.user_sys_auth_div.split(",").indexOf(this.SYSTEM_USER) >= 0);
            }
            return false;
        },
        isSystemAdmin() {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                return user?.user_sys_auth_div.split(",").indexOf(this.SYSTEM_USER) >= 0;
            }
            return false;
        },
        isSupporter(checkOnly = false) {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                const authDivs = user?.user_sys_auth_div.split(",");
                const hasSupporter = authDivs.indexOf(this.SUPPORTER) >= 0;

                // そもそも支援担当者でなければ判定結果を返す.
                if (!hasSupporter) return hasSupporter;
                // checkOnlyがfalseならば判定結果を返す.
                if (!checkOnly) return hasSupporter;

                // 事務局 or システム管理者が与えられていなければtrue.
                return !((authDivs.indexOf(this.BACKOFFICE) >= 0) || (authDivs.indexOf(this.SYSTEM_USER) >= 0));
            }
            return false;
        },
        isBackOffice() {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                return user?.user_sys_auth_div.split(",").indexOf(this.BACKOFFICE) >= 0;
            }
            return false;
        },
        isSystemMaintain() {
            let userRaw = localStorage.getInfo();
            let user = null;

            if (userRaw.length) {
                user = JSON.parse(userRaw);
            }

            if (user) {
                return user?.user_sys_auth_div.split(",").indexOf(this.SYSTEM_MAINTAIN) >= 0;
            }
            return false;
        },
        //##################################################################################################################


        //##################################################################################################################
        // toast notificaation
        notifyInfo(message = "") {
            this.$toast.open({
                message,
                type: "info",
            });
        },
        notifyError(message = "") {
            this.$toast.open({
                message,
                type: "error",
            });
        },
        notifySuccess(message = "") {
            this.$toast.open({
                message,
                type: "success",
            });
        },
        notifyWarn(message = "") {
            this.$toast.open({
                message,
                type: "warning",
            });
        },
        notifyNoSearchResults(message = "") {
            this.notifyInfo("検索結果がありません。" + (message ? "(" + message + ")" : ""));
        },
        notifyInputInvalidToast() {
            this.$toast.open({
                message: "入力内容に誤りがあります。",
                type: "warning",
            });
        },
        notifyInputWarnToast(item = "") {

            const m = (item) ? item + " " : "";

            this.$toast.open({
                message: m + "入力内容に誤りがないかを確認してください。",
                type: "warning",
            });
        },
        //##################################################################################################################

        ...mapMutations({
            showLoading: "common/showLoading",
            hideLoading: "common/hideLoading"
        }),
        classSegment(segment, ignoreOverDue = false) {
            return {
                "bg-first-support": this.checkSupportFirst(segment) || this.checkSplitFirstSupportSecond(segment),
                "bg-continue-support": this.checkContinueSupport(segment),
                "bg-mid-eval": this.checkMidEval(segment),
                "bg-final-support": this.checkSupportFinal(segment),
                "bg-overdue": !ignoreOverDue && ((segment?.scheduled_support_date ? segment?.scheduled_support_date : segment?.plan_support_date) < this.formatTime(new Date(), "YYYY-MM-DD")),
                "confirmed": parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_CONFIRM
            }
        },
        /**
         * 支援フロー領域タイトル部 カラー.
         * @param {*} flow 
         * @returns 
         */
        statusSegmentColor(flow) {
            const dateCurrent = moment(new Date()).format("YYYY-MM-DD")
            let showError = false //期限超過フラグ
            let _this = this

            // 支援フローステータスが、「開始前」or「実施中」の場合
            if (flow.support_flow_status == this.BEFORE_START_SUPPORT || 
                flow.support_flow_status == this.SUPPORT_IN_PROGRESS) {
                flow.health_support_flow_segments.forEach(function(segment) {
                    // 進捗が「計画」、かつ支援中止フラグOFF、かつ計画日が期限超過.
                    if (parseInt(segment?.support_progress_status) === _this.PLAN && segment?.support_stop_flag != 1) {
                        if((segment?.plan_support_date && segment?.plan_support_date) < dateCurrent) {
                            showError = true
                            return
                        }
                    }
                    // 進捗が「予定」or「実施中」、かつ支援中止フラグOFF、かつ支援予定日が期限超過.
                    if ([_this.SCHEDULE, _this.IN_PROGRESS].includes(parseInt(segment?.support_progress_status)) && segment?.support_stop_flag != 1
                        && segment?.scheduled_support_date
                        && segment?.scheduled_support_date < dateCurrent) {
                        showError = true
                        return
                    }
                })
            }            

            return {
                "bg-normal": (!showError && (flow?.support_flow_status == this.BEFORE_START_SUPPORT || flow?.support_flow_status == this.SUPPORT_IN_PROGRESS)),
                "bg-overdue": showError,
                "bg-finish-support": flow?.support_flow_status == this.SUPPORT_COMPLETE,
                "bg-end-halfway-support": flow?.support_flow_status == this.END_HALFWAY,
                "bg-first-interview-decline": flow?.support_flow_status == this.FIRST_INTERVIEW_DECLINE,
            }
        },


        checkContinueSupport(segment) {
            return parseInt(segment?.support_exec_div) === this.CONTINUOUS_SUPPORT;
        },

        // 初回支援かどうか
        checkSupportFirst(segment) {
            return parseInt(segment?.health_support_form?.support_form_div) === this.codeStatusSupportFirst;
        },
        // 初回支援分割実施かどうか
        checkSplitFirstSupport(segment) {
            // 初回支援でない
            // if (parseInt(segment?.support_exec_div) !== this.codeStatusSupportFirst) {
            //     return false;
            // }
            // 分割実施ではない
            if (!segment.split_first_support_flag) {
                return false;
            }
            return true;
        },
        // 初回支援分割実施の1回目かどうか
        checkSplitFirstSupportFirst(segment) {
            if (!this.checkSplitFirstSupport(segment)) {
                return false;
            }
            // 1回目
            if (segment.support_flow_seq > 0) {
                return false;
            }
            return true;
        },
        // 初回支援分割実施の2回目かどうか
        checkSplitFirstSupportSecond(segment) {
            if (!this.checkSplitFirstSupport(segment)) {
                return false;
            }
            // 1回目
            if (segment.support_flow_seq == 0) {
                return false;
            }
            return true;
        },
        checkSupportA(segment) {
            return parseInt(segment?.health_support_form?.support_form_div) === this.codeStatusSupportA;
        },
        checkSupportB(segment) {
            return parseInt(segment?.health_support_form?.support_form_div) === this.codeStatusSupportB;
        },
        checkSupport(segment) {
            return parseInt(segment?.health_support_form?.support_form_div) === this.codeStatusSupportContinuing;
        },
        checkSupportFinal(segment) {
            return parseInt(segment?.support_exec_div) === this.FINAL_EVALUATION;
        },
        checkMidEval(segment) {
            return segment?.mid_eval_flg;
        },

        // (支援形態) 個別支援かどうか
        checkHealthSupportFormIndividual(supportFormCode) {
            const code = supportFormCode.slice(-2);
            return code == "10" || code == "15";
        },

        // (支援形態) グループ支援かどうか
        checkHealthSupportFormGroup(supportFormCode) {
            const code = supportFormCode.slice(-2);
            return code == "20" || code == "25";
        },

        // (支援形態) 電話かどうか
        checkHealthSupportFormPhone(supportFormCode) {
            return supportFormCode.slice(-2) == "30";
        },

        // (支援形態) 電話かどうか
        checkHealthSupportFormEmail(supportFormCode) {
            return supportFormCode.slice(-2) == "40";
        },

        formatTime(value, format, offset = "+0900") {
            if (moment(value).isValid()) {
                return moment(value).locale("ja").utcOffset(offset).format(format);
            }
            return ""
        },

        // After clean code
        processTagColor(segment, supportFlowStatus, previewSegment) {
            if (parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_CONFIRM) {
                // セグメントの進捗状況が「確定」
                return "block-segment-gray";
            } else {
                // セグメントの進捗状況が「確定」以外の場合.

                if (segment?.support_stop_flag == 1 && (supportFlowStatus == 40 || supportFlowStatus == 50)) {
                    // 支援中止フラグON、かつ支援フローステータスが「途中終了」or「初回面接辞退」の場合
                    return "block-segment-blue";
                } else {
                    // 期限超過判定用
                    const dateCurrent = moment(new Date()).format("YYYY-MM-DD")
                    // 支援フローステータスが「開始前」or「実施中」or「完了」の場合
                    if (supportFlowStatus != 40 && supportFlowStatus != 50) {
                        // 支援進捗状況が「計画」の場合
                        if (parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_PLAN) {
                            // [セグメントの計画日] < 現在日 (計画日を過ぎている)場合.
                            if (segment?.plan_support_date && segment?.plan_support_date < dateCurrent) {
                                // 期限超過色.
                                return "block-segment-pink";
                            } else {
                                // (直前セグメントが未定義 or 直前セグメントが確定)、かつ現在セグメントが「確定」以外
                                if ((previewSegment?.support_progress_status == null
                                        || parseInt(previewSegment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_CONFIRM)
                                    && parseInt(segment?.support_progress_status) !== this.SUPPORT_FLOW_STATUS_CONFIRM) {
                                    return "block-segment-green";
                                }
                            }
                        }

                        // 支援進捗状況が「予定」or「実施中」の場合
                        if (parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_SCHEDULE
                            || parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_PROGRESS) {
                            // [セグメントの支援予定日] < 現在日 (予定日を過ぎている)場合.
                            if (segment?.scheduled_support_date && segment?.scheduled_support_date < dateCurrent) {
                                // 期限超過色.
                                return "block-segment-pink";
                            } else {
                                // (直前セグメントが未定義 or 直前セグメントが確定)、かつ現在セグメントが「確定」以外
                                if ((previewSegment?.support_progress_status == null ||
                                        parseInt(previewSegment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_CONFIRM)
                                    && parseInt(segment?.support_progress_status) !== this.SUPPORT_FLOW_STATUS_CONFIRM) {
                                    return "block-segment-green";
                                }
                            }
                        }
                        return "block-segment-white";
                    }
                }
            }
            return parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_CONFIRM
        },
        segmentConfirm(segment) {
            return parseInt(segment?.support_progress_status) === this.SUPPORT_FLOW_STATUS_CONFIRM
        },
        segmentDraft(segment) {
            return parseInt(segment?.support_progress_status) === this.SUPPORT_COMPLETE;
        },
        async getCodeDefine(group, value = "") {
            if (!window.localStorage.getItem(group+value)) {
                try {
                    let txt = "";
                    if (value !== "") {
                        txt = `&value_field=${value}`;
                    }
                    await Repository.get(`code_define?group=${group}${txt}`).then(res => {
                        window.localStorage.setItem(group+value, JSON.stringify(res.data))
                    });
                } catch (e) {
                    window.localStorage.setItem(group+value, JSON.stringify([]))
                }
            }
            return JSON.parse(window.localStorage.getItem(group+value));
        },
        getFiscalYear() {
            return moment().subtract(3, "months").year();
        },
        getFiscalYearFromDateStr(dateStr){
            try{
                return moment(dateStr, ["YYYYMMDD", "YYYY-MM-DD", "YYYY/MM/DD"]).subtract(3, "months").year();

            }catch(ex){
                return this.getFiscalYear();
            }
        },
        getFiscalYearFromDateArr(addDefault = false) {
            let arr = [
                moment(new Date()).subtract(3, "months").add(1, "Y").format("Y"),
                moment(new Date()).subtract(3, "months").format("Y"),
                moment(new Date()).subtract(3, "months").subtract(1, "Y").format("Y"),
            ].map((year) => ({
                text: year.slice(-2) + "年度",
                value: parseInt(year),
            }));

            if(addDefault) {
                arr.unshift(
                    {
                        text: "",
                        value: null,
                    }
                )
            }
            return arr
        },

        /**
         * 切り替わりの年度
         */
        getPeriodSwitchingYears() {
            return [2013, 2018, 2024];
        },


        /**
         * 保健指導期間
         */
        getHealthGuidancePeriods(fiscalYear) {
            // 第1期 2008 ~ 2012
            // 第2期 2013 ~ 2017
            // 第3期 2018 ~ 2023
            // 第4期 2024 ~ 2029
            if (!fiscalYear) {
                return []
            }
            // 切り替わり年度 (両方を選択できるようにする)
            if (fiscalYear == 2013) {
                return [1, 2];
            }
            if (fiscalYear == 2018) {
                return [2, 3];
            }
            if (fiscalYear == 2024) {
                return [3, 4]
            }
            // それ以外
            if (fiscalYear <= 2012) {
                return [1];
            } else if (fiscalYear <= 2017) {
                return [2];
            } else if (fiscalYear <= 2023) {
                return [3];
            } else {
                return [4];
            }
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$toast.open({
                        message: "テキストをコピーしました。",
                        type: "success",
                    });
                })
        },

        //##################################################################################################################
        // 保健指導区分判定
        isHealthGuidanceDiv_ActiveSupport(hgDiv) {
            return hgDiv == 1;
        }

        //##################################################################################################################

    }
}

export default mixins
