<template>
    <v-row justify="center">
        <v-dialog
            :value="isShow"
            :persistent="persistent"
            :max-width="maxWidth"
            :fullscreen="fullscreen"
            :width="width"
            :transition="transition"
        >
            <slot></slot>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: "Dialog",
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: true
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        width: {
            type: [Number, String],
            default: undefined
        },
        maxWidth: {
            type: [Number, String],
            default: undefined
        },
        transition: {
            type: [Boolean, String],
            default: "dialog-transition"
        }
    }
};
</script>

<style scoped>

</style>